export const THREATMODEL_CLEAR = 'THREATMODEL_CLEAR';
export const THREATMODEL_DIAGRAM_APPLIED = 'THREATMODEL_DIAGRAM_APPLIED';
export const THREATMODEL_DIAGRAM_CLOSED = 'THREATMODEL_DIAGRAM_CLOSED';
export const THREATMODEL_DIAGRAM_MODIFIED = 'THREATMODEL_DIAGRAM_MODIFIED';
export const THREATMODEL_DIAGRAM_SAVED = 'THREATMODEL_DIAGRAM_SAVED';
export const THREATMODEL_DIAGRAM_SELECTED = 'THREATMODEL_DIAGRAM_SELECTED';
export const THREATMODEL_FETCH = 'THREATMODEL_FETCH';
export const THREATMODEL_FETCH_ALL = 'THREATMODEL_FETCH_ALL';
export const THREATMODEL_CREATE = 'THREATMODEL_CREATE';
export const THREATMODEL_CONTRIBUTORS_UPDATED = 'THREATMODEL_CONTRIBUTORS_UPDATED';
export const THREATMODEL_MODIFIED = 'THREATMODEL_MODIFIED;';
export const THREATMODEL_NOT_MODIFIED = 'THREATMODEL_NOT_MODIFIED';
export const THREATMODEL_RESTORE = 'THREATMODEL_RESTORE';
export const THREATMODEL_SAVE = 'THREATMODEL_SAVE';
export const THREATMODEL_SELECTED = 'THREATMODEL_SELECTED';
export const THREATMODEL_STASH = 'THREATMODEL_STASH';
export const THREATMODEL_UPDATE = 'THREATMODEL_UPDATE';

export default {
    clear: THREATMODEL_CLEAR,
    contributorsUpdated: THREATMODEL_CONTRIBUTORS_UPDATED,
    create: THREATMODEL_CREATE,
    diagramApplied: THREATMODEL_DIAGRAM_APPLIED,
    diagramClosed: THREATMODEL_DIAGRAM_CLOSED,
    diagramModified: THREATMODEL_DIAGRAM_MODIFIED,
    diagramSaved: THREATMODEL_DIAGRAM_SAVED,
    diagramSelected: THREATMODEL_DIAGRAM_SELECTED,
    fetch: THREATMODEL_FETCH,
    fetchAll: THREATMODEL_FETCH_ALL,
    modified: THREATMODEL_MODIFIED,
    notModified: THREATMODEL_NOT_MODIFIED,
    restore: THREATMODEL_RESTORE,
    saveModel: THREATMODEL_SAVE,
    selected: THREATMODEL_SELECTED,
    stash: THREATMODEL_STASH,
    update: THREATMODEL_UPDATE
};
