export default{
    'version': '2.0.0',
    'summary': {
        'title': 'New Threat Model',
        'owner': '',
        'description': '',
        'id': 0
    },
    'detail': {
        'contributors': [],
        'diagrams': [],
        'diagramTop': 0,
        'reviewer': '',
        'threatTop': 0
    }
};
