export default{
    'version': '1.6.1',
    'summary': {
        'title':'New threat model'
    },
    'detail': {
        'contributors': [],
        'diagrams': []
    }
};
